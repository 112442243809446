/**
 * Function that sanitize a string
 * @param input text to need be sanitize
 * @returns
 */
function sanitizeHTML(input) {
  /**
   * Convert the string to an HTML document
   * @return {HTMLElement} An HTML document
   */
  function stringToHTML() {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body || document.createElement('body');
  }
  /**
   * Remove <script> elements
   * @param  {HTMLElement} html The HTML
   */
  function removeScripts(element) {
    const scripts = Array.from(element.querySelectorAll('script'));
    for (let script of scripts) {
      script.remove();
    }
  }
  /**
   * Check if the attribute is potentially dangerous
   * @param  {String}  name  The attribute name
   * @param  {String}  value The attribute value
   * @return {Boolean}       If true, the attribute is potentially dangerous
   */
  function isPossiblyDangerous(name, value) {
    const val = value.replace(/\s+/g, '').toLowerCase();
    return name.startsWith('on') || ['src', 'href', 'xlink:href'].includes(name) && (val.includes('javascript:') || val.includes('data:'));
  }
  /**
   * Remove potentially dangerous attributes from an element
   * @param  {HTMLElement} elem The element
   */
  function removeAttributes(elem) {
    const attributes = elem.attributes;
    for (let {
      name,
      value
    } of attributes) {
      if (isPossiblyDangerous(name, value)) {
        elem.removeAttribute(name);
      }
    }
    elem.getAttributeNames().forEach(x => {
      if (x.startsWith('on')) {
        elem.removeAttribute(x);
      }
    });
  }
  /**
   * Remove dangerous stuff from the HTML document's nodes
   * @param  {HTMLElement} element The HTML document
   */
  function clean(element) {
    let nodes = element.children;
    for (let node of nodes) {
      removeAttributes(node);
      clean(node);
    }
  }
  // Convert the string to HTML
  let html = stringToHTML();
  // Sanitize it
  removeScripts(html);
  clean(html);
  // If the user wants HTML nodes back, return them
  // Otherwise, pass a sanitized string back
  return html.innerHTML;
}
export { sanitizeHTML as s };